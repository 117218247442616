<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    :header="`Delete '${userAnalyzerCustomPromptName}'`"
  >
    <div class="dialog-body">
      <p>Are you sure you wish to delete this userAnalyzerCustomPrompt?</p>
    </div>

    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('deleteUserAnalyzerCustomPrompt')"
        :size-class="'fa-2x'"
      />

      <button
        :disabled="$isLoading('deleteUserAnalyzerCustomPrompt')"
        @click="closeDialog"
      >
        Cancel
      </button>

      <button
        class="btn-primary"
        :disabled="$isLoading('deleteUserAnalyzerCustomPrompt')"
        @click="submit"
      >
        Delete userAnalyzerCustomPrompt
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { loadable } from 'vue-is-loading';
import { getErrorMessage } from '#lib/Errors';
import { successToast, errorToast } from '#ui/lib/toast';
import LoadingSpinner from '#ui/components/LoadingSpinner';

// Setup

const store = useStore();
const id = ref(0);
const userAnalyzerCustomPromptName = ref('');

// Open/close

const visible = ref(false);
const openDialog = (userAnalyzerCustomPrompt) => {
  id.value = userAnalyzerCustomPrompt.id;
  userAnalyzerCustomPromptName.value = userAnalyzerCustomPrompt.name;

  visible.value = true;
};
const closeDialog = () => {
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

// Delete

const deleteUserAnalyzerCustomPrompt = loadable(
  (values) => store.dispatch('UserAnalyzerCustomPrompts/deleteUserAnalyzerCustomPrompt', values),
  'deleteUserAnalyzerCustomPrompt',
  getCurrentInstance(),
);
const submit = async () => {
  try {
    await deleteUserAnalyzerCustomPrompt({ id: id.value });
    closeDialog();
    successToast('User Analyzer Custom Prompt deleted.');
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not delete userAnalyzerCustomPrompt.'));
  }
};
</script>
