<template>
  <div class="card">
    <h2 class="card-header">
      userAnalyzerCustomPrompts
    </h2>
    <div class="card-body">
      <DataTable
        :fields="userAnalyzerCustomPromptFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListUserAnalyzerCustomPrompt')"
        :actions="userAnalyzerCustomPromptActions"
        :empty-message="'No userAnalyzerCustomPrompts'"
        class="w-full"
      />

      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListUserAnalyzerCustomPrompt')"
          @click="$refs.newUserAnalyzerCustomPromptDialog.openDialog()"
        >
          New User Analyzer Custom Prompt
        </button>
      </div>
    </div>

    <NewUserAnalyzerCustomPromptDialog ref="newUserAnalyzerCustomPromptDialog" />
    <EditUserAnalyzerCustomPromptDialog ref="editUserAnalyzerCustomPromptDialog" />
    <DeleteUserAnalyzerCustomPromptDialog ref="deleteUserAnalyzerCustomPromptDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { USER_ANALYZER_CUSTOM_PROMPT_MODEL_FIELDS } from '#features/userAnalyzerCustomPrompts/lib/models/UserAnalyzerCustomPromptModel';
import NewUserAnalyzerCustomPromptDialog from '#features/userAnalyzerCustomPrompts/ui/dialogs/NewUserAnalyzerCustomPromptDialog';
import EditUserAnalyzerCustomPromptDialog from '#features/userAnalyzerCustomPrompts/ui/dialogs/EditUserAnalyzerCustomPromptDialog';
import DeleteUserAnalyzerCustomPromptDialog from '#features/userAnalyzerCustomPrompts/ui/dialogs/DeleteUserAnalyzerCustomPromptDialog';

// Setup

const store = useStore();
const currentPage = computed(() => store.state.UserAnalyzerCustomPrompts.currentPage);
const totalPages = computed(() => store.state.UserAnalyzerCustomPrompts.totalPages);
const userAnalyzerCustomPromptFields = computed(() => omit(USER_ANALYZER_CUSTOM_PROMPT_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('UserAnalyzerCustomPrompts/getList', values),
  'getListUserAnalyzerCustomPrompt',
  getCurrentInstance(),
);

// Row actions

const editUserAnalyzerCustomPromptDialog = ref(null);
const openEditDialog = (userAnalyzerCustomPrompt) => {
  editUserAnalyzerCustomPromptDialog.value.openDialog(userAnalyzerCustomPrompt);
};

const deleteUserAnalyzerCustomPromptDialog = ref(null);
const openDeleteDialog = (userAnalyzerCustomPrompt) => {
  deleteUserAnalyzerCustomPromptDialog.value.openDialog(userAnalyzerCustomPrompt);
};

const userAnalyzerCustomPromptActions = [
  { name: 'Edit User Analyzer Custom Prompt', fn: openEditDialog },
  { name: 'Delete User Analyzer Custom Prompt', fn: openDeleteDialog },
];
</script>
